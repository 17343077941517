import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';
import { IStatusBadgeProps } from './types';

export const StatusBadge = ({ label, variant = 'default', className }: IStatusBadgeProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, classes[variant], className)}>
      <Typography className={classes.statusText} variant="body1">
        {label}
      </Typography>
    </div>
  );
};

export default StatusBadge;
