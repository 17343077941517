import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMatchScoreParams } from '@/client/redux/actions';
import { matchScoreParametersSelector } from '@/client/redux/selectors';
import { storeMatchScoreParams } from '@/client/redux/store/matches.slice';
import { IUsePopoverContentProps } from './types';

const useScoreParamsPopoverContent = ({ candidateId, jobId }: IUsePopoverContentProps) => {
  const dispatch = useDispatch();
  const matchScoreParamsData = useSelector(matchScoreParametersSelector);

  useEffect(() => {
    dispatch(loadMatchScoreParams({ candidateId, jobId }));
    return () => {
      dispatch(storeMatchScoreParams(null));
    };
  }, [dispatch, candidateId, jobId]);

  return { matchScoreParamsData };
};

export default useScoreParamsPopoverContent;
