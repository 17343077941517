import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: theme.spacing(5),
  },
  typography: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  preloader: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
