import { IStatusBadgeProps } from '@/client/components/StatusBadge/types';
import { ApplicantStatus } from '@/enums';
import { IUseApplicationJobCardProps } from './types';

const badgePropsMapping: Record<ApplicantStatus, IStatusBadgeProps> = {
  [ApplicantStatus.Submitted]: { label: 'Submitted', variant: 'success' },
};

const useApplicationJobCard = ({ applicationStatus }: IUseApplicationJobCardProps) => {
  return {
    badgeProps: badgePropsMapping[applicationStatus],
  };
};

export default useApplicationJobCard;
