import clsx from 'clsx';
import { Button } from '@/client/components';
import useCardStyles from '@/client/cards/styles';
import useCard from '@/client/cards/useCard';
import useStyles from './styles';
import { ICandidateCardButtonProps } from './types';

const CardButton = ({ label, className, onClick, ...rest }: ICandidateCardButtonProps) => {
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const { onButtonClickHandler } = useCard();

  return (
    <Button
      className={clsx(cardClasses.button, classes.button, className)}
      onClick={onButtonClickHandler(onClick)}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default CardButton;
