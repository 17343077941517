import { Box, Typography, CircularProgress } from '@material-ui/core';
import { camelCaseToTitleCase } from '@/utils';
import usePopoverContent from './useScoreParamsPopoverContent';
import { IPopoverContentProps } from './types';
import useStyles from './styles';

export const ScoreParamsPopoverContent = ({
  candidateId,
  jobId,
  ...props
}: IPopoverContentProps) => {
  const { matchScoreParamsData } = usePopoverContent({ candidateId, jobId });
  const classes = useStyles();

  return (
    <Box className={classes.popoverContent} {...props}>
      {matchScoreParamsData ? (
        Object.entries(matchScoreParamsData).map(([matchParam, value], index) => (
          <Typography key={index} className={classes.typography}>{`${camelCaseToTitleCase(
            matchParam,
          )}: ${value}`}</Typography>
        ))
      ) : (
        <div className={classes.preloader}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default ScoreParamsPopoverContent;
