import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popover: {},
  iconButton: {
    '&.MuiIconButton-root': {
      padding: theme.spacing(2),
    },
  },
}));

export default useStyles;
