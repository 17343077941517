import clsx from 'clsx';
import Image from 'next/image';
import {
  CustomImage,
  ReadMoreText,
  StatusBadge,
  TooltipInfo,
  ScoreParamsPopover,
} from '@/client/components';
import { Divider, Typography } from '@material-ui/core';
import useApplicationJobCard from '@/client/cards/ApplicationJobCard/useApplicationJobCard';
import { ICandidateMatchesJobCardProps } from './types';
import useCardStyles from '../styles';
import useStyles from './styles';
import useCard from '../useCard';

const CandidateMatchesJobCard = ({
  data,
  textLengthLimit,
  compensationLabelInfo,
  className,
  responsiveActions = false,
  actionComponents,
  onCardClick,
  ...props
}: ICandidateMatchesJobCardProps) => {
  const classes = useStyles();
  const cardClasses = useCardStyles();

  const { badgeProps } = useApplicationJobCard({ applicationStatus: data.applicationStatus || 0 });
  const { scoreMatch } = useCard(data.score);

  return (
    <div
      className={clsx(cardClasses.wrapper, cardClasses.cardHover, className)}
      onClick={onCardClick}
      aria-hidden="true"
      {...props}
    >
      <div className={classes.infoBlock}>
        <div className={classes.logoBlock}>
          <CustomImage className={classes.logo} imageUri={data.logoUri} />
          {data.isMatch && (
            <div className={clsx(cardClasses.badge, classes.badgeHidden)}>
              <div className={cardClasses.badgeIcon}>
                <Image src="/icons/star-icon.svg" width={12} height={12} />
              </div>
              <Typography className={cardClasses.badgeTitle} variant="body1">
                Match {scoreMatch}
              </Typography>
            </div>
          )}
        </div>
        <div>
          <div className={classes.titleBlock}>
            <Typography
              className={clsx(cardClasses.title, classes.title)}
              variant="h3"
              title={data.title}
            >
              {data.title}
            </Typography>
            {data.isMatch && (
              <>
                <div className={clsx(cardClasses.badge, classes.badge)}>
                  <div className={cardClasses.badgeIcon}>
                    <Image src="/icons/star-icon.svg" width={12} height={12} />
                  </div>
                  <Typography className={cardClasses.badgeTitle} variant="body1">
                    Match {scoreMatch}
                  </Typography>
                </div>
                {data.candidateId && (
                  <ScoreParamsPopover
                    className={classes.scoreParamsPopover}
                    candidateId={data.candidateId}
                    jobId={data.jobId}
                  />
                )}
              </>
            )}
          </div>
          <div className={classes.companyWrapper}>
            <CustomImage className={clsx(classes.logo, classes.logoSm)} imageUri={data.logoUri} />
            <div>
              <div className={classes.companyBlock}>
                <Typography className={classes.subtitle} variant="body1">
                  {data.companyName}
                </Typography>
                <div className={classes.point} />
                <Typography className={clsx(classes.subtitle, classes.workType)} variant="body1">
                  {data.workType}
                </Typography>
              </div>
              <Typography className={classes.date} variant="body1">
                {data.date}
              </Typography>
              <div>
                {typeof data?.applicationStatus === 'number' && (
                  <div className={classes.statusSpacing}>
                    <StatusBadge {...badgeProps} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.paymentWrapper}>
          {data.payment !== null && (
            <>
              <div className={classes.paymentBlock}>
                <Typography className={classes.paymentText} component="span" variant="h3">
                  ${data.payment}
                  <span className={classes.paymentTextHour}>/hour</span>
                </Typography>
                {data.paymentPerYear && (
                  <Typography
                    className={cardClasses.secondaryPaymentText}
                    component="span"
                    variant="h3"
                  >
                    ${data.paymentPerYear} per year
                    {compensationLabelInfo && (
                      <TooltipInfo title={compensationLabelInfo} className={cardClasses.tooltip} />
                    )}
                  </Typography>
                )}
                <div
                  className={clsx(classes.buttonsBlock, classes.hiddenButton, {
                    [classes.dispalyNone]: responsiveActions,
                  })}
                >
                  {actionComponents}
                </div>
              </div>
              <Divider className={cardClasses.divider} flexItem orientation="vertical" />
            </>
          )}
          <div className={classes.workTypeBlock}>
            <div className={classes.locationIcon}>
              <Image src="/icons/location-icon.svg" width={20} height={20} />
            </div>
            <Typography className={classes.subtitle} variant="body1" component="span">
              {data.workType}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.descriptionBlock}>
        <ReadMoreText
          text={data.description ?? ''}
          lengthLimit={textLengthLimit}
          textClass={classes.description}
          className={cardClasses.readMoreTextWrapper}
          hasReadMoreButton
        />
        <div
          className={clsx(classes.buttonsBlock, {
            [classes.extraBlockButtons]: responsiveActions,
          })}
        >
          {actionComponents}
        </div>
      </div>
    </div>
  );
};

export default CandidateMatchesJobCard;
