import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'fit-content',
    height: 'fit-content',
    padding: theme.spacing(3, 6),
    borderRadius: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 7),
    },
  },
  statusText: {
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 500,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  default: {
    backgroundColor: '#ECEDED',
    '& .MuiTypography-root': {
      color: theme.palette.secondaryBlack,
    },
  },
  success: {
    backgroundColor: '#DBF5E4',
    '& .MuiTypography-root': {
      color: '#0FBC49',
    },
  },
  warning: {
    backgroundColor: '#FDF2DE',
    '& .MuiTypography-root': {
      color: '#F5A623',
    },
  },
  info: {
    backgroundColor: '#E3F0FC',
    '& .MuiTypography-root': {
      color: theme.palette.lightBlue,
    },
  },
  error: {
    backgroundColor: '#F9DDDF',
    '& .MuiTypography-root': {
      color: theme.palette.error.main,
    },
  },
}));

export default useStyles;
