import { useCallback, useState, MouseEvent } from 'react';

const useScoreParamsPopover = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const togglePopoverHandler = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopoverHandler = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  return {
    open,
    anchorEl,
    togglePopoverHandler,
    closePopoverHandler,
  };
};

export default useScoreParamsPopover;
