import Image from 'next/image';
import { IconButton, Popover } from '@material-ui/core';
import ScoreParamsPopoverContent from './ScoreParamsPopoverContent';
import useScoreParamsPopover from './useScoreParamsPopover';
import { IScoreParamsPopoverProps } from './types';
import useStyles from './styles';

export const ScoreParamsPopover = ({
  candidateId,
  jobId,
  className,
  ...props
}: IScoreParamsPopoverProps) => {
  const { anchorEl, open, togglePopoverHandler, closePopoverHandler } = useScoreParamsPopover();
  const classes = useStyles();

  return (
    <div className={className} {...props}>
      <IconButton
        className={classes.iconButton}
        onClick={togglePopoverHandler}
        disableRipple
        disableFocusRipple
        disableTouchRipple
      >
        <Image src="/icons/question-sign-icon.svg" width={17} height={17} />
      </IconButton>
      <Popover
        open={open}
        onClose={closePopoverHandler}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ScoreParamsPopoverContent candidateId={candidateId} jobId={jobId} />
      </Popover>
    </div>
  );
};

export default ScoreParamsPopover;
