import { MouseEvent, useCallback, useMemo } from 'react';

const useCard = (score?: number) => {
  const onButtonClickHandler = useCallback(
    (onClick?: () => void) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick?.();
    },
    [],
  );

  const onResumeDownloadHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const scoreMatch = useMemo(() => {
    return score ? ` ${score}` : '';
  }, [score]);

  return {
    scoreMatch,
    onButtonClickHandler,
    onResumeDownloadHandler,
  };
};

export default useCard;
