import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: 'calc(100% - 80px)',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  statusSpacing: {
    marginTop: theme.spacing(3),
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },
  logoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(15),
    },
  },
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: '50%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(26),
      height: theme.spacing(26),
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(30),
      height: theme.spacing(30),
      display: 'block',
    },
  },
  logoSm: {
    display: 'none',
    marginRight: theme.spacing(7),
    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
  },
  date: {
    marginTop: theme.spacing(),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '22px',
    },
  },
  subtitle: {
    marginTop: theme.spacing(),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.darkBlue,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      fontSize: '14px',
      lineHeight: '22px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  infoBlock: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  companyWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  companyBlock: {
    display: 'flex',
  },
  workTypeBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  descriptionBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(11),
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(50),
      lineHeight: '22px',
      flexGrow: 1,
    },
  },
  point: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    alignSelf: 'center',
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.darkBlue,
    borderRadius: '50%',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 6),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  workType: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  locationIcon: {
    display: 'flex',
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
  },
  workTypeHidden: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  paymentWrapper: {
    minWidth: theme.spacing(91),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottom: `1px solid ${theme.palette.border}`,
    margin: theme.spacing(6, -10),
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      margin: theme.spacing(0, 0, 0, 'auto'),
      padding: theme.spacing(0),
      border: 'none',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  paymentBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
  },
  paymentText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: theme.palette.darkBlue,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      fontWeight: 600,
    },
  },
  paymentTextHour: {
    marginLeft: theme.spacing(1),
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      fontSize: '16px',
      lineHeight: '18px',
    },
  },
  buttonsBlock: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      maxWidth: theme.spacing(91),
      marginTop: theme.spacing(6),
      minWidth: theme.spacing(91),
      marginLeft: theme.spacing(20),
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(10),
    },
  },
  hiddenButton: {
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
  },
  badge: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  badgeHidden: {
    margin: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  dispalyNone: {
    display: 'none',
  },
  extraBlockButtons: {
    display: 'block',
    marginTop: theme.spacing(0),
  },
  scoreParamsPopover: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
